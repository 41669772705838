import './Obstetrics_and_Gynecology.css';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
 





const Obstetrics_and_GynecologyList = ({ addToCart }) => {
  const { t, i18n } = useTranslation("global");
  const [calls, setCalls] = useState([]);
  const [filteredCalls, setFilteredCalls] = useState([]);
  const [location, setLocation] = useState('');
  const [naturalBirth, setNaturalBirth] = useState('');
  const [roomType, setRoomType] = useState('');
  const [service, setService] = useState('');
  const [loading, setLoading] = useState(false);
  const [searchCompleted, setSearchCompleted] = useState(false);
  const [serviceRequired, setServiceRequired] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const isButtonDisabled = !location && !naturalBirth && !roomType && !service;

  const validServices = ["5", "-1", "-2", "-5", "-7", "-9", "-11", "-15", "-17"];

  const getDirection = (reverse = false) => {
    const baseDirection = i18n.language === "ar" ? "rtl" : "ltr";
    return reverse ? (baseDirection === "rtl" ? "ltr" : "rtl") : baseDirection;
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    console.log('Service selected:', service);

    if (!service || !validServices.includes(service)) {
      setServiceRequired(true);
      setErrorMessage(t("Obstetrics_and_Gynecology.selectServiceMessage"));
      console.log('Service required set to true');
      return;
    }

    setServiceRequired(false);
    setErrorMessage('');
    console.log('Service required set to false');
    setLoading(true);
    const language = i18n.language === "ar" ? "Ar" : "En";
    const data = await import(`./translation/${language}/Call.json`);
    const calls = data.default;
    console.log('Data loaded:', calls);

    const filtered = calls.filter(service => {
      return (
        (location ? service.Location.toLowerCase() === location.toLowerCase() : true) &&
        (naturalBirth ? service.Services.toLowerCase() === naturalBirth.toLowerCase() : true) &&
        (roomType ? service.RoomType.toLowerCase() === roomType.toLowerCase() : true)
      );
    });

    console.log('Filtered data:', filtered);

    setCalls(calls);
    setFilteredCalls(filtered);
    setLoading(false);
    setSearchCompleted(true);
  };

  const getTitleDescription = (title) => t(`Obstetrics_and_Gynecology.${title}`);
  const getBranchDescription = (Branch) => t(`Obstetrics_and_Gynecology.${Branch}`);
  const getNaturalBirthDescription = (serviceName) => t(`Obstetrics_and_Gynecology.${serviceName}`);
  const getRoomTypeDescription = (roomType) => t(`Obstetrics_and_Gynecology.${roomType}`);

  const calculatePrice = (basePrice, discountPercentage) => {
    return basePrice - (basePrice * (Number(discountPercentage) / 100));
  };

  return (
    <div dir={getDirection()} className='bluethore'>
      <div className='desktop'>
        <div className='forhead'><br />
          <h1 className="TitlContw">{t("Obstetrics_and_Gynecology.call0")}</h1>
          <h1 className="TitlContKhafet">{t("Obstetrics_and_Gynecology.call11")}</h1>
        </div>
        <form onSubmit={handleSearch} className="search-form">
          <div className="row">
            <select id="LocationSelect" value={location} onChange={e => setLocation(e.target.value)}>
              <option value="">{t("Obstetrics_and_Gynecology.ChoseLoc")}</option>
              <option value="Riyadh">{t("Obstetrics_and_Gynecology.Riyadh")}</option>
              <option value="Jeddah">{t("Obstetrics_and_Gynecology.Jeddah")}</option>
              <option value="Mecca">{t("Obstetrics_and_Gynecology.Mecca")}</option>
            </select>
            <select id="NaturalBirthSelect" value={naturalBirth} onChange={(e) => setNaturalBirth(e.target.value)}>
              <option value="">{t("Obstetrics_and_Gynecology.cal5")}</option>
              <option value="normal">{t("Obstetrics_and_Gynecology.cal6")}</option>
              <option value="k1">{t("Obstetrics_and_Gynecology.cal7")}</option>
              <option value="k2">{t("Obstetrics_and_Gynecology.cal8")}</option>
              <option value="k3">{t("Obstetrics_and_Gynecology.cal9")}</option>
              <option value="k4">{t("Obstetrics_and_Gynecology.cal10")}</option>
              <option value="k5">{t("Obstetrics_and_Gynecology.cal11")}</option>
              <option value="t2">{t("Obstetrics_and_Gynecology.cal12")}</option>
              <option value="nw">{t("Obstetrics_and_Gynecology.cal12_1")}</option>
              <option value="k1t">{t("Obstetrics_and_Gynecology.cal12_2")}</option>
            </select>
            {/* <select id="RoomTypeSelect" value={roomType} onChange={(e) => setRoomType(e.target.value)}>
              <option value="">{t("Obstetrics_and_Gynecology.cal13")}</option>
              <option value="mr">{t("Obstetrics_and_Gynecology.SharedRoom")}</option>
              <option value="pr">{t("Obstetrics_and_Gynecology.privatRome")}</option>
              <option value="fcr">{t("Obstetrics_and_Gynecology.FirestClassRoom")}</option>
              <option value="rr">{t("Obstetrics_and_Gynecology.RoryalRoom")}</option>
            </select> */}
            <select id="service" value={service} onChange={(e) => setService(e.target.value)}>
              <option value="">{t("Obstetrics_and_Gynecology.cal18")}</option>
              <option value="5">{t("Obstetrics_and_Gynecology.cal19")}</option>
              <option value="-1">{t("Obstetrics_and_Gynecology.cal20")}</option>
              <option value="-2">{t("Obstetrics_and_Gynecology.cal21")}</option>
              <option value="-5">{t("Obstetrics_and_Gynecology.cal22")}</option>
              <option value="-7">{t("Obstetrics_and_Gynecology.cal23")}</option>
              <option value="-9">{t("Obstetrics_and_Gynecology.cal24")}</option>
              <option value="-11">{t("Obstetrics_and_Gynecology.cal25")}</option>
              <option value="-15">{t("Obstetrics_and_Gynecology.cal26")}</option>
              <option value="-17">{t("Obstetrics_and_Gynecology.cal27")}</option>
            </select>

            <div className='aligne'>
              <button type="submit" className="blueNewGREEN" disabled={isButtonDisabled}>
                <span className="capital">{t("Obstetrics_and_Gynecology.cal36")}</span><span>{t("Obstetrics_and_Gynecology.earch")}</span>
              </button>
              {serviceRequired && <p className="error-message">{errorMessage}</p>}
              <br />
            </div>

          </div>
        </form>
      </div>
      {loading ? <p>{t("Obstetrics_and_Gynecology.cal28")}</p> : null}

      {searchCompleted && !loading && filteredCalls.length === 0 ? (
        <div>
          <p>{t("Obstetrics_and_Gynecology.noServiceAvailable")}</p> {/* Display message when no services are available */}
        </div>
      ) : null}

      {searchCompleted && !loading && filteredCalls.length > 0 ? (
        <div className='Desplay'>
        <table className='dektopDes'>
          <thead>
            <tr>
              <th></th>
              <th>{t("Obstetrics_and_Gynecology.cal29")}</th>
              <th>{t("Obstetrics_and_Gynecology.cal30")}</th>
              <th>{t("Obstetrics_and_Gynecology.cal27_1")}</th>
              <th>{t("Obstetrics_and_Gynecology.cal31")}</th>
              <th>{t("Obstetrics_and_Gynecology.cal32")}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {filteredCalls.map(call => (
              <tr key={call.id}>
                <td><img src={call.image1} alt="Call Visual Representation" /></td>
                <td className="titleBranchCell">
                  <span>{getTitleDescription(call.Title)}</span><br />
                  <span className="branchDescription">{getBranchDescription(call.Branch)}</span>
                </td>
                <td>{getNaturalBirthDescription(call.Services)}</td>
                <td>{calculatePrice(call.PriceAtYourPlatform, service).toFixed(2)}&nbsp;{t("Services.Pur6")}</td>
                <td>{getRoomTypeDescription(call.RoomType)}</td>
                <td>{(call.PriceAtHospital - calculatePrice(call.PriceAtYourPlatform, service)).toFixed(2)}&nbsp;{t("Services.Pur6")}</td>
                <td >
                  <Link
                    to="/details"
                    state={{ data: call.id, type: 'call', service, roomType: call.RoomType }}
                    onClick={() => console.log('Navigating to details with:', { data: call.id, type: 'call', service, roomType: call.RoomType })}
                    className='aligndiae'
                  >
                    <button className="blueNewTkhile">{t("Obstetrics_and_Gynecology.cal35")}</button>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className='mobileDes' dir={getDirection(i18n.language)}>
          {filteredCalls.map(call => (
            <div className="cardsContainerma">
              <div className="cardsakhif" key={call.id}>
                <div className='hanaback'>
                  <div className='textContainer'>
                    <p>{getTitleDescription(call.Title)}</p>
                    <p className='smallt'>{getBranchDescription(call.Branch)}</p>
                  </div>
                  <img src={call.image1} alt="wait or refresh the website" />
                </div>
                <br />
                <p className='aligne'>{getNaturalBirthDescription(call.Services)}</p>
                <div className='articleContent'>
                  <div className='det_gr'>
                    <div className='gelluleNour'>
                      <p className='titlenormalcolapsText' dir={getDirection(call.reverse)}>
                        {calculatePrice(call.PriceAtYourPlatform, service).toFixed(2)}&nbsp;
                        {t("Services.Pur6")}
                      </p>
                    </div>
                    <div className='gelluleNour'>
                      <p className='titlenormalcolapsText'>
                        {t("Obstetrics_and_Gynecology.estimatedCost")}
                      </p>
                    </div>
                    <div className='gelluleNour'>
                      <p className='titlenormalcolapsText'>{getRoomTypeDescription(call.RoomType)}</p>
                    </div>
                    <div className='gelluleNour'>
                      <p className='titlenormalcolapsText'>{t("Obstetrics_and_Gynecology.accommodationLevel")}</p>
                    </div>
                    <p className='titlenormalcolapsText' dir={getDirection(call.reverse)}>
                      {(call.PriceAtHospital - calculatePrice(call.PriceAtYourPlatform, service)).toFixed(2)}&nbsp;
                      {t("Services.Pur6")}
                    </p>
                    <div className='gelluleNour'>
                      <p className='titlenormalcolapsText'>{t("Obstetrics_and_Gynecology.savingsValue")}</p>
                    </div>
                  </div>
                </div>
                <div
                  className='card-actions'
                >
                  <Link
                    to="/details"
                    state={{ data: call.id, type: 'call', service, roomType: call.RoomType }}
                    onClick={() => console.log('Navigating to details with:', { data: call.id, type: 'call', service, roomType: call.RoomType })}
                    className='aligndiae'
                  >
                    <button
                      className='blueNewTkhile'
                    >{t("Obstetrics_and_Gynecology.cal35")}</button>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      ) : null}
    </div>
  );
};

export default Obstetrics_and_GynecologyList;
