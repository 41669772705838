import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Borde.css';
import { useTranslation } from 'react-i18next';
import Document from './images/green/Document2.png';

const Borde = () => {
  const { t, i18n } = useTranslation("global");
  const navigate = useNavigate();

  const [searchQuery, setSearchQuery] = useState('');
  const [additionalServices, setAdditionalServices] = useState([]);
  const [filteredServices, setFilteredServices] = useState([]);
  const [debouncedQuery, setDebouncedQuery] = useState(searchQuery);

  // Fetch data on component mount
  useEffect(() => {
    const loadData = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_API_URL);
        const data = await response.json();
        
        // Log and check if data is an array
        if (Array.isArray(data)) {
          setAdditionalServices(data);
        } else {
          console.error("Data is not in the expected format (array)");
          setAdditionalServices([]);
        }
      } catch (error) {
        console.error("Error loading additional services data:", error);
      }
    };

    loadData();
  }, []);

  // Debouncing search query to improve performance
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedQuery(searchQuery);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [searchQuery]);

  // Handle search query change
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Filter services based on the search query
  useEffect(() => {
    if (debouncedQuery && Array.isArray(additionalServices)) {
      const filtered = additionalServices.filter(service => {
        const title = service.title?.toLowerCase() || '';
        const summary = service.summary?.toLowerCase() || '';
        const includedServices = service.included_services?.toLowerCase() || '';

        return (
          title.includes(debouncedQuery.toLowerCase()) ||
          summary.includes(debouncedQuery.toLowerCase()) ||
          includedServices.includes(debouncedQuery.toLowerCase())
        );
      });
      setFilteredServices(filtered);
    } else {
      setFilteredServices([]);
    }
  }, [debouncedQuery, additionalServices]);

  // Navigate to the service details page
  const handleServiceClick = (service) => {
    if (service && service.service_id) {
      navigate(`/DetailsAdd?id=${service.service_id}`);
    } else {
      console.error("Service ID is missing");
    }
  };

  // Determine the text direction based on the language (RTL for Arabic, LTR for others)
  const getDirection = () => {
    return i18n.language === "ar" ? "rtl" : "ltr";
  };

  return (
    <div className="treatment-container12" style={{ direction: getDirection() }}>
      <div className="header-section12">
        <div className="talib012">
          <div className="column12 image-column12 imagbord">
            <img src={Document} alt="Document icon" className="talib2image12" />
          </div>
          <div className="column12 text-column12">
            <p className='talib112'>
              {t("Gorfe.Headsoltitle")}
            </p>
          </div>
        </div>
      </div>

      <div className="search-bar-wrapper">
        <div className="input-group">
          <input
            type="text"
            placeholder={t("Gorfe.search_placeholder")}
            value={searchQuery}
            onChange={handleSearchChange}
            className="search-input professional-input"
            aria-label={t("Gorfe.search_placeholder")}
          />
        </div>

        {/* Show dropdown if search query is present and there are filtered results */}
        {searchQuery && filteredServices.length > 0 && (
          <div className="dropdown-results">
            {filteredServices.map(service => (
              <div
                key={service.service_id}
                className="dropdown-item"
                onClick={() => handleServiceClick(service)}
              >
                <div className="item-content">
                  <img src={service.image} alt={service.title} className="service-image" />
                  <span className="service-title">{service.title}</span>
                </div>
              </div>
            ))}
          </div>
        )}

        {/* Show message if no results are found */}
        {searchQuery && filteredServices.length === 0 && (
          <p>{t("services.no_services_found")}</p>
        )}
      </div>
    </div>
  );
};

export default Borde;
