import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; 
import Pill from "./Pill"; 
import './General_Surgery.css';

const Home_Care = ({ addToCart }) => {
  const { t } = useTranslation("global");
  const [additionalServices, setAdditionalServices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => { 
    const loadData = async () => {
      try {
        setIsLoading(true);
        const url = process.env.REACT_APP_API_URL2;
        
        console.log("Fetching data from URL:", url);
        
        const response = await fetch(url);

        if (!response.ok) {
          console.log('Full response:', response);
          throw new Error(`Error fetching data: ${response.statusText}`);
        }

        const data = await response.json(); 
        console.log("Loaded data from backend:", data);

        // Filtering services for 'Dermatology' category
        const filteredServices = data.filter(service => service.category === t('services.Dermatology'));
        console.log("Filtered services:", filteredServices);

        setAdditionalServices(filteredServices);
      } catch (error) {
        console.error("Error loading additional services data:", error.message);
      } finally {
        setIsLoading(false);
      }
    };

    loadData();
  }, [t]);  // Include `t` as a dependency so it reloads when translation changes

  return (
    <div>
      <br />
      <h1 className="TitlContw">{t('services.Dermatology').replace(/-/g, ' ')}</h1>
      <div className='speed1'>
        {isLoading ? (
          <div>
            <Pill />
            {console.log("Currently loading data...")}
          </div>
        ) : (
          additionalServices.length > 0 ? (
            additionalServices.map(service => (
              <div className="speed2" key={service.service_id}> 
                <Link to={`/DetailsAdd?id=${service.service_id}`}>
                  <img className="speed3" src={service.image} alt={service.title} />
                  <h5 className="speed4">{service.title}</h5>
                  <p className="speed5">{service.summary}</p>
                </Link>
              </div>
            ))
          ) : (
            <p>{t("services.no_services_found")}</p>
          )
        )}
      </div>
    </div>
  );
};

export default Home_Care;
