import React, { useState, useEffect, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Borde from './components/Borde.js';
import General_Surgery from './General_Surgery'; //
import Digestive_System_and_Endoscopy from './Digestive_System_and_Endoscopy.js';
import Lab_Tests from './Lab_Tests.js';
import Obstetrics_and_GynecologyList from './Obstetrics_and_GynecologyList.js';
import Gynecology from './Gynecology.js';
import Delayed_childbearing from './Delayed_childbearing.js';
import Urinary_tract from './Urinary_tract.js';
import Sterility from './Sterility.js';
import Home_Care from './Home_Care.js';
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon } from '@mui/icons-material';
import Slideshow from "./pages/Slideshow";
import Gorfe from './components/Gorfe.js';

import './Services.css';  
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { useSpring, animated } from 'react-spring';

const Services = () => { 
  const { t, i18n } = useTranslation("global");
  const [openArticle, setOpenArticle] = useState(null);

  const getDirection = () => {
    return i18n.language === "ar" ? "rtl" : "ltr";
  };
  const toggleArticle = (article) => {
    setOpenArticle(current => current === article ? null : article);
  };

  const { category } = useParams(); 
  const [selectedCategory, setSelectedCategory] = useState(t('services.Obstetrics_and_Gynecology'));
  const navigate = useNavigate();

  useEffect(() => {
    if (category) {
      setSelectedCategory(category);
    }
  }, [category]);

  const services = [
    { 
      id: 1, 
      title: t('services.title1'), 
      category: t('services.Children') 
    },
    { 
      id: 2, 
      title: t('services.title2'), 
      category: t('services.Obstetrics_and_Gynecology') 
    },
    { 
      id: 3, 
      title: t('services.title3'), 
      category: t('services.Dermatology') 
    },
    { 
      id: 4, 
      title: t('services.title4'), 
      category: t('services.Psychiatry') 
    },
    { 
      id: 5, 
      title: t('services.title5'), 
      category: t('services.Cardiology') 
    },
    { 
      id: 6, 
      title: t('services.title6'), 
      category: t('services.Male_Fertility') 
    },
    { 
      id: 7, 
      title: t('services.title7'), 
      category: t('services.Cosmetic_Surgery') 
    },
    { 
      id: 8, 
      title: t('services.title8'), 
      category: t('services.General_Surgery') 
    },
    { 
      id: 9, 
      title: t('services.title9'), 
      category: t('services.Digestive_System_and_Endoscopy') 
    }

  ];

  const categories = [
    t('services.Children'),
    t('services.Obstetrics_and_Gynecology'),
    t('services.Dermatology'),
    t('services.Psychiatry'),
    t('services.Cardiology'),
    t('services.Male_Fertility'),
    t('services.Cosmetic_Surgery'),
    t('services.General_Surgery'),
    t('services.Digestive_System_and_Endoscopy')
  ];

  const getTitleForCategory = (category) => {
    const service = services.find(service => service.category === category);
    return service ? service.title : t('services.no_services_found');
  };

  const filteredServices = useMemo(() => {
    return selectedCategory === 'All'
      ? services
      : services.filter(service => service.category === t(`services.${selectedCategory.replace(/\s+/g, '_')}`));
  }, [selectedCategory, services, t]);

  const handleCategoryChange = (category) => {
    navigate(`/${category === 'All' ? '' : category}`);
  };
 
  return ( 
    <div className="servicescontainer" style={{ direction: getDirection() }}>
      <div className='morese'>      

       <Borde />
       <br/>
      <nav className="categories-nav ">
        <div className="categories-wrapper">
          {categories.map(cat => (
            <button
              key={cat}
              className={`category-btn ${selectedCategory === cat ? 'active' : ''}`}
              onClick={() => handleCategoryChange(cat)}
            >
           
               {getTitleForCategory(cat)}
            </button>
          ))}
        </div>
      </nav>
     
      {selectedCategory === t('services.Obstetrics_and_Gynecology') ? (
        <Obstetrics_and_GynecologyList />
        ) : selectedCategory === t('services.General_Surgery') ? (
        <General_Surgery />  
        ) : selectedCategory === t('services.Male_Fertility') ? (
          <Sterility />
        ) : selectedCategory === t('services.Cosmetic_Surgery') ? (
          <Urinary_tract />    
        ) : selectedCategory === t('services.Cardiology') ? (
          <Delayed_childbearing />      
        ) : selectedCategory === t('services.Psychiatry') ? (
          <Lab_Tests />   
        ) : selectedCategory === t('services.Dermatology') ? (
          <Home_Care />    
        ) : selectedCategory === t('services.Digestive_System_and_Endoscopy') ? (
          <Digestive_System_and_Endoscopy />
      ) : selectedCategory === t('services.Children') ? (
        <Gynecology /> 
      ) : (
        <ul className="service-list">
          {filteredServices.length === 0 ? (
            <p>{t("services.no_services_found")}</p>
          ) : (
            filteredServices.map(service => (
              <li >
              
              </li>
            ))
          )}
        </ul>
      )}  
      </div>



      <Slideshow />
      <Gorfe />
      <div className="bahite whiteDbodye" dir={getDirection(i18n.language)}>
          <div className='why'>
            <div className="padingtop">
              <br />
              <br />
              <p className="warda">{t("Buying_guide.FAQs")}</p>
            </div>
            <br />
          </div>
          <div>
            <div className="articleContent whiteH">
              <button className="button-collapses" onClick={() => toggleArticle('FAQs1')} aria-expanded={openArticle === 'FAQs1'}>
                <animated.span style={{ transform: openArticle === 'FAQs1' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#0056B3' }}>
                  {openArticle === 'FAQs1' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </animated.span>
                <p className="textCont">{t("Buying_guide.FAQs1")}</p>
              </button>
              {openArticle === 'FAQs1' && (
                <animated.div style={{
                  backgroundColor: '#F4F6FA',
                  borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
                }}>
                  <p className="normalcolapsText">{t("Buying_guide.FAQs2")}</p>
                </animated.div>
              )}
              {openArticle !== 'FAQs1' && <div className="line"></div>}
            </div>

            <div className="articleContent whiteH">
              <button className="button-collapses" onClick={() => toggleArticle('subFAQs8')} aria-expanded={openArticle === 'subFAQs8'}>
                <animated.span style={{ transform: openArticle === 'subFAQs8' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#0056B3' }}>
                  {openArticle === 'subFAQs8' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </animated.span>
                <p className="textCont">{t("Buying_guide.FAQs5")}</p>
              </button>
              {openArticle === 'subFAQs8' && (
                <animated.div style={{
                  backgroundColor: '#F4F6FA',
                  borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
                }}>
                  <p className="normalcolapsText">{t("Buying_guide.FAQs4")}</p>
                </animated.div>
              )}
              {openArticle !== 'subFAQs8' && <div className="line"></div>}
            </div>

            <div className="articleContent whiteH">
              <button className="button-collapses" onClick={() => toggleArticle('ourSpecificationend1')} aria-expanded={openArticle === 'ourSpecificationend1'}>
                <animated.span style={{ transform: openArticle === 'ourSpecificationend1' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#0056B3' }}>
                  {openArticle === 'ourSpecificationend1' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </animated.span>
                <p className="textCont">{t("Buying_guide.FAQs3")}</p>
              </button>
              {openArticle === 'ourSpecificationend1' && (
                <animated.div style={{
                  backgroundColor: '#F4F6FA',
                  borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
                }}>
                  <p className="titlenormalcolapsText">{t("Buying_guide.tot1")}</p>
                  <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot2")}</p>
                  <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot3")}</p>
                  <p className="titlenormalcolapsText">{t("Buying_guide.tot5")}</p>
                  <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot4")}</p>
                  <p className="titlenormalcolapsText">{t("Buying_guide.tot6")}</p>
                  <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot7")}</p>
                  <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot8")}</p>
                  <p className="titlenormalcolapsText">{t("Buying_guide.tot9")}</p>
                  <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot10")}</p>
                  <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot11")}</p>
                  <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot12")}</p>
                  <p className="titlenormalcolapsText">{t("Buying_guide.tot13")}</p>
                  <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot14")}</p>
                  <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot15")}</p>
                  <p className="titlenormalcolapsText">{t("Buying_guide.tot16")}</p>
                  <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot17")}</p>
                  <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot18")}</p>
                  <p className="titlenormalcolapsText">{t("Buying_guide.tot19")}</p>
                  <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot20")}</p>
                  <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot21")}</p>
                  <p className="titlenormalcolapsText">{t("Buying_guide.tot22")}</p>
                  <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot23")}</p>
                  <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot24")}</p>
                  <p className="titlenormalcolapsText">{t("Buying_guide.tot25")}</p>
                  <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot26")}</p>
                  <div className="backgroundsnowwhite">
                    <div className="columncontact ">
                      <p className="titlenormalcolapsText">{t("Buying_guide.tot27")}</p>
                      <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot28")}</p>

                      <div className="habaa">
                        <Link to="/SendMessage" state={{ MessagTitle: "تواصل" }}>
                          <button className="sendbtnOne sendbtn">
                            {t("Buying_guide.tot29")}
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </animated.div>
              )}
              {openArticle !== 'ourSpecificationend1' && <div className="line"></div>}
            </div>

            <div className="articleContent whiteH">
              <button className="button-collapses" onClick={() => toggleArticle('subFAQs10')} aria-expanded={openArticle === 'subFAQs10'}>
                <animated.span style={{ transform: openArticle === 'subFAQs10' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#0056B3' }}>
                  {openArticle === 'subFAQs10' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </animated.span>
                <p className="textCont">{t("Buying_guide.FAQs7")}</p>
              </button>
              {openArticle === 'subFAQs10' && (
                <animated.div style={{
                  backgroundColor: '#F4F6FA',
                  borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
                }}>
                  <p className="normalcolapsText">{t("Buying_guide.FAQs8")}</p>
                </animated.div>
              )}
              {openArticle !== 'subFAQs10' && <div className="line"></div>}
            </div>

            <div className="articleContent whiteH">
              <button className="button-collapses" onClick={() => toggleArticle('subFAQs11')} aria-expanded={openArticle === 'subFAQs11'}>
                <animated.span style={{ transform: openArticle === 'subFAQs11' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#0056B3' }}>
                  {openArticle === 'subFAQs11' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </animated.span>
                <p className="textCont">{t("Buying_guide.FAQs9")}</p>
              </button>
              {openArticle === 'subFAQs11' && (
                <animated.div style={{
                  backgroundColor: '#F4F6FA',
                  borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
                }}>
                  <p className="normalcolapsText">{t("Buying_guide.FAQs10")}</p>
                </animated.div>
              )}
              {openArticle !== 'subFAQs11' && <div className="line"></div>}
            </div>

            <div className="articleContent whiteH">
              <button className="button-collapses " onClick={() => toggleArticle('subFAQs12')} aria-expanded={openArticle === 'subFAQs12'}>
                <animated.span style={{ transform: openArticle === 'subFAQs12' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#0056B3' }}>
                  {openArticle === 'subFAQs12' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </animated.span>
                <p className="textCont">{t("Buying_guide.FAQs11")}</p>
              </button>
              {openArticle === 'subFAQs12' && (
                <animated.div style={{
                  backgroundColor: '#F4F6FA',
                  borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
                }}>
                  <p className="normalcolapsText">{t("Buying_guide.FAQs12")}</p>
                </animated.div>
              )}
            </div>
          </div>
        </div>
        <div className="backgroundsnowwhite" dir={getDirection(i18n.language)}>
          <div className="columncontact">
            <div className="snow">
              <br />
            </div>

            <div className="snow">
              <div className="centre">
                <p className="subTitleContfak11">{t("Buying_guide.tit101")}
                </p>
              </div>
              <br />
              <div className="buttonRow">
                <div className="widthbutton">
                  <Link to="/SendMessage" state={{ MessagTitle: "اطرح سؤالا" }}>
                    <button className="blueNewTkhile buttoncleropfaten">
                      {t("faq.sendyouropinion")}
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
};

export default Services;



// import React, { useRef, useEffect, useState } from "react";
// import { useTranslation } from 'react-i18next';
// import { Link } from "react-router-dom";
// import Slider from 'react-slick';
// import { useSpring, animated } from 'react-spring';
// import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon } from '@mui/icons-material';
// import { useAuthContext } from "./context/AuthContext";
// import Solution from "./pages/Solution.js";
// import './Services.css';
// import imageA from './components/images/image_without.png';
// import icon1Img from './components/images/icon1.png';
// import icon2Img from './components/images/icon2.png';
// import Slideshow from "./pages/Slideshow";
// import General_Surgery from './General_Surgery';
// import CableList from './CabelList';
// import Obstetrics_and_GynecologyList from './Obstetrics_and_GynecologyList.js';
// import AddOnServices from './AddOnServices.js';
// import DermatologyList from './DermatologyList.js';
// import Gorfe from './components/Gorfe.js';
// import Borde from './components/Borde.js';

// const Services = () => {
//   const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
//   const { user } = useAuthContext();
//   const { t, i18n } = useTranslation("global");
//   const [cartItems, setCartItems] = useState([]);
//   const [additionalServices, setAdditionalServices] = useState([]);
//   const [openSection, setOpenSection] = useState(null);
  // const [selectedCategory, setSelectedCategory] = useState('Obstetrics_and_Gynecology');
//   const [openArticle, setOpenArticle] = useState(null);

//   const toggleSection = (section) => {
//     setOpenSection(current => current === section ? null : section);
//   };

//   const toggleArticle = (article) => {
//     setOpenArticle(current => current === article ? null : article);
//   };

 
 
//   const getDirection = () => {
//     return i18n.language === "ar" ? "rtl" : "ltr";
//   };

//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);

//   useEffect(() => {
//     const handleResize = () => {
//       setIsMobile(window.innerWidth < 768);
//     };

//     window.addEventListener('resize', handleResize);
//     return () => {
//       window.removeEventListener('resize', handleResize);
//     };
//   }, []);

//   const categories = [
//     { Title: ' ', value: 'Obstetrics_and_Gynecology', image: t("Services.Pur1") },
//     { Title: ' ', value: 'phone', image: t("Services.Pur4") },
//     { Title: ' ', value: 'AddOnServices', image: t("Services.Pur4_2") },
//   ];

//   const addToCart = (item) => {
//     setCartItems((prevCartItems) => {
//       const existingItem = prevCartItems.find((cartItem) => cartItem.id === item.id);
//       if (existingItem) {
//         return prevCartItems.map((cartItem) =>
//           cartItem.id === item.id ? { ...cartItem, quantity: cartItem.quantity + 1 } : cartItem
//         );
//       }
//       return [...prevCartItems, { ...item, quantity: 1 }];
//     });
//   };

//   const removeItem = (itemId) => {
//     setCartItems(cartItems.filter((cartItem) => cartItem.id !== itemId));
//   };

//   const getTotal = () => {
//     return cartItems.reduce((total, cartItem) => total + (cartItem.Price * cartItem.quantity), 0);
//   };

//   const renderCartItem = (item) => (
//     <div className="carto" key={item.id} dir={getDirection(i18n.language)}>
//       <div className="cartfo">
//         <img src={item.image1} alt={`Product: ${item.Title}`} className="cartmage" />
//         <div>
//           <p className="cart-item-title">{item.Title}</p>
//           <p className="cart-item-price">{item.Price}</p>
//           <p className="cart-item-quantity">Quantity: {item.quantity}</p>
//         </div>
//       </div>
//       <div className="cart-item-actions">
//         <button onClick={() => removeItem(item.id)}>Remove</button>
//       </div>
//     </div>
//   );

//   const renderPage = () => {
//     switch (selectedCategory) {
//       case 'phone': return <General_Surgery addToCart={addToCart} />;
//       case 'Obstetrics_and_Gynecology': return <Obstetrics_and_GynecologyList addToCart={addToCart} />;
//       case 'cabel': return <CableList addToCart={addToCart} />;
//       case 'before': return <DermatologyList addToCart={addToCart} />;
//       case 'AddOnServices': return <AddOnServices addToCart={addToCart} />;
//       default: return null;
//     }
//   }; 

//   const iconSliderRef = useRef(null);
  


//   const viewProps = {
//     user, t, i18n, categories, setSelectedCategory, additionalServices, selectedCategory,
//     renderPage, cartItems, renderCartItem: (item) => renderCartItem(item),   iconSliderRef
//   };

//   return (
//     <div>
//       {isMobile ? <MobileView {...viewProps} /> : <DesktopView {...viewProps} />}
//       <div className='bluebodye' >
//         <div className='marvelbodye' >
//         </div>

//       </div>
//       <div className='bluebodye'>
//         <Slideshow />
//         <Gorfe />

//         <div className="bahite whiteDbodye" dir={getDirection(i18n.language)}>
//           <div className='why'>
//             <div className="padingtop">
//               <br />
//               <br />
//               <p className="warda">{t("Buying_guide.FAQs")}</p>
//             </div>
//             <br />
//           </div>
//           <div>
//             <div className="articleContent whiteH">
//               <button className="button-collapses" onClick={() => toggleArticle('FAQs1')} aria-expanded={openArticle === 'FAQs1'}>
//                 <animated.span style={{ transform: openArticle === 'FAQs1' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#0056B3' }}>
//                   {openArticle === 'FAQs1' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
//                 </animated.span>
//                 <p className="textCont">{t("Buying_guide.FAQs1")}</p>
//               </button>
//               {openArticle === 'FAQs1' && (
//                 <animated.div style={{
//                   backgroundColor: '#F4F6FA',
//                   borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
//                 }}>
//                   <p className="normalcolapsText">{t("Buying_guide.FAQs2")}</p>
//                 </animated.div>
//               )}
//               {openArticle !== 'FAQs1' && <div className="line"></div>}
//             </div>

//             <div className="articleContent whiteH">
//               <button className="button-collapses" onClick={() => toggleArticle('subFAQs8')} aria-expanded={openArticle === 'subFAQs8'}>
//                 <animated.span style={{ transform: openArticle === 'subFAQs8' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#0056B3' }}>
//                   {openArticle === 'subFAQs8' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
//                 </animated.span>
//                 <p className="textCont">{t("Buying_guide.FAQs5")}</p>
//               </button>
//               {openArticle === 'subFAQs8' && (
//                 <animated.div style={{
//                   backgroundColor: '#F4F6FA',
//                   borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
//                 }}>
//                   <p className="normalcolapsText">{t("Buying_guide.FAQs4")}</p>
//                 </animated.div>
//               )}
//               {openArticle !== 'subFAQs8' && <div className="line"></div>}
//             </div>

//             <div className="articleContent whiteH">
//               <button className="button-collapses" onClick={() => toggleArticle('ourSpecificationend1')} aria-expanded={openArticle === 'ourSpecificationend1'}>
//                 <animated.span style={{ transform: openArticle === 'ourSpecificationend1' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#0056B3' }}>
//                   {openArticle === 'ourSpecificationend1' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
//                 </animated.span>
//                 <p className="textCont">{t("Buying_guide.FAQs3")}</p>
//               </button>
//               {openArticle === 'ourSpecificationend1' && (
//                 <animated.div style={{
//                   backgroundColor: '#F4F6FA',
//                   borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
//                 }}>
//                   <p className="titlenormalcolapsText">{t("Buying_guide.tot1")}</p>
//                   <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot2")}</p>
//                   <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot3")}</p>
//                   <p className="titlenormalcolapsText">{t("Buying_guide.tot5")}</p>
//                   <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot4")}</p>
//                   <p className="titlenormalcolapsText">{t("Buying_guide.tot6")}</p>
//                   <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot7")}</p>
//                   <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot8")}</p>
//                   <p className="titlenormalcolapsText">{t("Buying_guide.tot9")}</p>
//                   <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot10")}</p>
//                   <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot11")}</p>
//                   <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot12")}</p>
//                   <p className="titlenormalcolapsText">{t("Buying_guide.tot13")}</p>
//                   <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot14")}</p>
//                   <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot15")}</p>
//                   <p className="titlenormalcolapsText">{t("Buying_guide.tot16")}</p>
//                   <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot17")}</p>
//                   <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot18")}</p>
//                   <p className="titlenormalcolapsText">{t("Buying_guide.tot19")}</p>
//                   <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot20")}</p>
//                   <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot21")}</p>
//                   <p className="titlenormalcolapsText">{t("Buying_guide.tot22")}</p>
//                   <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot23")}</p>
//                   <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot24")}</p>
//                   <p className="titlenormalcolapsText">{t("Buying_guide.tot25")}</p>
//                   <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot26")}</p>
//                   <div className="backgroundsnowwhite">
//                     <div className="columncontact ">
//                       <p className="titlenormalcolapsText">{t("Buying_guide.tot27")}</p>
//                       <p className="titlenormalcolapsTextULX">{t("Buying_guide.tot28")}</p>

//                       <div className="habaa">
//                         <Link to="/SendMessage" state={{ MessagTitle: "تواصل" }}>
//                           <button className="sendbtnOne sendbtn">
//                             {t("Buying_guide.tot29")}
//                           </button>
//                         </Link>
//                       </div>
//                     </div>
//                   </div>
//                 </animated.div>
//               )}
//               {openArticle !== 'ourSpecificationend1' && <div className="line"></div>}
//             </div>

//             <div className="articleContent whiteH">
//               <button className="button-collapses" onClick={() => toggleArticle('subFAQs10')} aria-expanded={openArticle === 'subFAQs10'}>
//                 <animated.span style={{ transform: openArticle === 'subFAQs10' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#0056B3' }}>
//                   {openArticle === 'subFAQs10' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
//                 </animated.span>
//                 <p className="textCont">{t("Buying_guide.FAQs7")}</p>
//               </button>
//               {openArticle === 'subFAQs10' && (
//                 <animated.div style={{
//                   backgroundColor: '#F4F6FA',
//                   borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
//                 }}>
//                   <p className="normalcolapsText">{t("Buying_guide.FAQs8")}</p>
//                 </animated.div>
//               )}
//               {openArticle !== 'subFAQs10' && <div className="line"></div>}
//             </div>

//             <div className="articleContent whiteH">
//               <button className="button-collapses" onClick={() => toggleArticle('subFAQs11')} aria-expanded={openArticle === 'subFAQs11'}>
//                 <animated.span style={{ transform: openArticle === 'subFAQs11' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#0056B3' }}>
//                   {openArticle === 'subFAQs11' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
//                 </animated.span>
//                 <p className="textCont">{t("Buying_guide.FAQs9")}</p>
//               </button>
//               {openArticle === 'subFAQs11' && (
//                 <animated.div style={{
//                   backgroundColor: '#F4F6FA',
//                   borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
//                 }}>
//                   <p className="normalcolapsText">{t("Buying_guide.FAQs10")}</p>
//                 </animated.div>
//               )}
//               {openArticle !== 'subFAQs11' && <div className="line"></div>}
//             </div>

//             <div className="articleContent whiteH">
//               <button className="button-collapses " onClick={() => toggleArticle('subFAQs12')} aria-expanded={openArticle === 'subFAQs12'}>
//                 <animated.span style={{ transform: openArticle === 'subFAQs12' ? 'rotate(360deg)' : 'rotate(0deg)', fontSize: 30, color: '#0056B3' }}>
//                   {openArticle === 'subFAQs12' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
//                 </animated.span>
//                 <p className="textCont">{t("Buying_guide.FAQs11")}</p>
//               </button>
//               {openArticle === 'subFAQs12' && (
//                 <animated.div style={{
//                   backgroundColor: '#F4F6FA',
//                   borderBottom: '0.5px solid rgba(0, 0, 0, 0.05)'
//                 }}>
//                   <p className="normalcolapsText">{t("Buying_guide.FAQs12")}</p>
//                 </animated.div>
//               )}
//             </div>
//           </div>
//         </div>
//         <div className="backgroundsnowwhite" dir={getDirection(i18n.language)}>
//           <div className="columncontact">
//             <div className="snow">
//               <br />
//             </div>

//             <div className="snow">
//               <div className="centre">
//                 <p className="subTitleContfak11">{t("Buying_guide.tit101")}
//                 </p>
//               </div>
//               <br />
//               <div className="buttonRow">
//                 <div className="widthbutton">
//                   <Link to="/SendMessage" state={{ MessagTitle: "اطرح سؤالا" }}>
//                     <button className="blueNewTkhile buttoncleropfaten">
//                       {t("faq.sendyouropinion")}
//                     </button>
//                   </Link>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// const DesktopView = ({ user, t, i18n, categories, setSelectedCategory, selectedCategory, additionalServices, renderPage, cartItems, renderCartItem,   iconSliderRef }) => {
//   const getDirection = () => {
//     return i18n.language === "ar" ? "rtl" : "ltr";
//   };

//   const Icon = ({ img, title, text, id }) => (
//     <div className="cart">
//       <Link to="/Service_provider" state={{ id: id, image: img }}>
//         <img src={img} alt={title} className="cart-img-top" />
//         <div className="cartsbody">
//           <h5 className="cartstitle">{title}</h5>
//           <p className="cartstext">{text}</p>
//         </div>
//       </Link>
//     </div>
//   );

//   return (
//     <div className={`app-container ${i18n.language === "ar" ? "rtl" : "ltr"}`} dir={getDirection(i18n.language)}>
//       <div className='bluebody'>
//         <div className="">
//           <Borde />

//           <div className="bannernaksol" style={{
//             background: 'linear-gradient(#FFFFFF,#FFFFFF)',
//             backgroundSize: 'cover',
//             backgroundPosition: 'center',
//             height: '100px',
//             position: 'relative',
//             textAlign: 'center',
     
//           }}>
           

          
//             <div className="categorycntainerContainer">
//               <div className="categorycntainer ">
//                 {categories
//                   .map((category) => (
//                     <div
//                       key={category.value}
//                       onClick={() => setSelectedCategory(category.value)}
//                       className={`categoryitem ${selectedCategory === category.value ? 'selected' : ''}`}
//                     >
//                       <p>{category.image}</p>
//                     </div>
//                   ))}
//               </div>
//             </div>
//           </div>
//           <div className="page-content">
//             {/* <br />
//             <br />
//             <br />
//             <br /> */}
//             {renderPage()}
//             <div className='Additional'>
            
//               <br />
//               <br />
//             </div>
//             <br />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// const MobileView = ({ user, t, i18n, iconSliderRef,  categories, setSelectedCategory, renderPage, cartItems, selectedCategory, renderCartItem, additionalServices, openArticle, openSection, toggleArticle, toggleSection }) => {
//   const getDirection = () => {
//     return i18n.language === "ar" ? "rtl" : "ltr";
//   };

//   const Icon = ({ img, title, text, id }) => (
//     <div className="cart">
//       <Link to="/Service_provider" state={{ id: id, image: img }}>
//         <img src={img} alt={title} className="cart-img-top" />
//         <div className="cartsbody">
//           <h5 className="cartstitle">{title}</h5>
//           <p className="cartstext">{text}</p>
//         </div>
//       </Link>
//     </div>
//   );

//   return (
//     <div className={`app-containerrr ${i18n.language === "ar" ? "rtl" : "ltr"}`} dir={getDirection(i18n.language)}>
//       <div>
//         <Borde />
//         <div style={{
//           backgroundSize: 'cover',
//           backgroundPosition: 'center',
//           height: 'auto',
//           position: 'relative',
//           textAlign: 'center',
//           width: '100%',
//           display: 'flex',
//           justifyContent: 'center',
//           alignItems: 'center'
//         }}>
//           <div className="khayen">
//             <div className="categorycntainerPhone">
//               {categories
//                 .map((category) => (
//                   <div
//                     key={category.value}
//                     onClick={() => setSelectedCategory(category.value)}
//                     className={`categoryitemPhone ${selectedCategory === category.value ? 'selected' : ''}`}
//                   >
//                     <p>{category.image}</p>
//                   </div>
//                 ))}
//             </div>
//           </div>
//         </div>
//         <div className='maxcoffe'>
//           {renderPage()}
        
//         </div>

//         <div className="page_CCcontent">
//           <div className="oxbar">
       
//           </div>
//           <div className="shopping-cart-container">
//             {cartItems.length === 0 ? (
//               <p></p>
//             ) : (
//               cartItems.map((item) => renderCartItem(item))
//             )}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Services;
