import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; 
import Pill from "./Pill"; 
import './General_Surgery.css';

const Delayed_childbearing = ({ addToCart }) => {
  const { t, i18n } = useTranslation("global");
  const [additionalServices, setAdditionalServices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => { 
    const loadData = async () => {
      try {
        setIsLoading(true);
        const url = process.env.REACT_APP_API_URL2;
        console.log("Fetching data from URL:", url);
        const response = await fetch(url);

        if (!response.ok) {
          throw new Error(`Error fetching data: ${response.statusText}`);
        }
        const data = await response.json(); 
        console.log("Loaded data from backend:", data);
        const filteredServices = data.filter(service => service.category ===t('services.Cardiology'));
        console.log("Filtered services for Lab_Tests:", filteredServices);
        setAdditionalServices(filteredServices);
      } catch (error) {
        console.error("Error loading additional services data:", error);
      } finally {
        console.log("Data loading complete");
        setIsLoading(false);
      }
    };

    loadData();
  }, []);

  return (
    <div>
      <br />
      <h1 className="TitlContw">{t('services.Cardiology').replace(/-/g, ' ')}</h1>
      <div className='speed1'>
        {isLoading ? (
          <div>
            <Pill />
            {console.log("Currently loading data...")}
          </div>) : (
          additionalServices.length > 0 ? (
            additionalServices.map(service => (
              <div className="speed2" key={service.service_id}> 
                <Link to={`/DetailsAdd?id=${service.service_id}`}>
                  <img className="speed3" src={service.image} alt="Service Visual Representation" />
                  <h5 className="speed4">{service.title}</h5>
                  <p className="speed5">{service.summary}</p>
                </Link>
              </div>
            ))
          ) : (
            <p>{t("services.no_services_found")}</p>
          )
        )}
      </div>
    </div>
  );
};

export default Delayed_childbearing;
