import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './Details.css';
import { useTranslation } from 'react-i18next';
import icon2Img from './components/images/se.png';
import { useMediaQuery } from 'react-responsive';

const Details = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { data, service, roomType } = location.state || {};
  const { type } = location.state || { type: null };
  const [types, setTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { t, i18n } = useTranslation("global");

  const getDirection = () => {
    return i18n.language === "ar" ? "rtl" : "ltr";
  };

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 768px)'
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getTitleDescription = (title) => t(`Obstetrics_and_Gynecology.${title}`);
  const getBranchDescription = (Branch) => t(`Obstetrics_and_Gynecology.${Branch}`);
  const getNaturalBirthDescription = (serviceName) => t(`Obstetrics_and_Gynecology.${serviceName}`);

  useEffect(() => {
    const fetchTypes = async () => {
      if (!type) {
        setError('No type provided');
        return;
      }
      try {
        setLoading(true);
        const language = i18n.language === "ar" ? "Ar" : "En";
        const dataModule = await import(`./translation/${language}/Call.json`);
        const calls = dataModule.default;
        console.log('Fetched calls:', calls);

        const typeData = calls.filter(call => call.id === data);
        console.log('Filtered data:', typeData);

        if (typeData.length === 0) {
          throw new Error('No data found');
        }

        setTypes(typeData);
        setError(null);
      } catch (err) {
        console.error('Error fetching Types:', err);
        setError(`Failed to fetch data: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };
    fetchTypes();
  }, [type, data, i18n.language]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  const handleButtonClick = () => {
    const cartItems = [{
      type: types.length > 0 && getNaturalBirthDescription(types[0].Services),
      quantity: 1,
      image1: types.length > 0 && types[0].image1,
      Title: types.length > 0 && getTitleDescription(types[0].Title),
    }];
    const total = types.length > 0 && (Number(types[0].PriceAtYourPlatform - (types[0].PriceAtYourPlatform * service / 100)) + (Number(types[0].pro) || 0));
    const id = types.length > 0 && types[0].id;
    const hospitalName = types.length > 0 && getTitleDescription(types[0].Title);
    const hospitalLocation = types.length > 0 && getBranchDescription(types[0].Location);
    const birthDescription = types.length > 0 && getNaturalBirthDescription(types[0].Services);

    console.log("i am in details", {
      cartItems,
      total,
      id,
      hospitalName,
      hospitalLocation,
      birthDescription
    });

    navigate("/user-info", { state: { cartItems, total, id, hospitalName, hospitalLocation, birthDescription } });
  };

  return (
    <div className="backgroundwhiteASS" dir={getDirection(i18n.language)}>
      <div className="bannersol" style={{
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '1px',
        position: 'relative',
        textAlign: 'center'
      }}>
        {/* <div className="bannerText"><br />
          <div className='romel'>
            <div className="tabra1">
              <div className="tabra2">
                <div className="tabra6"></div>
                <p className="tabra4">{t("Details.hi1")}</p>
              </div>
              <div className="tabra5">......</div>
              <div className="tabra2">
                <div className="tabra3"></div>
                <p className="tabra4">{t("Details.hi2")}</p>
              </div>
              <div className="tabra5">......</div>
              <div className="tabra2">
                <div className="tabra3"></div>
                <p className="tabra4">{t("Details.hi3")}</p>
              </div>
              <div className="tabra5">......</div>
              <div className="tabra2">
                <div className="tabra3"></div>
                <p className="tabra4">{t("Details.hi4")}</p>
              </div>
              <div className="tabra5">......</div>
              <div className="tabra2">
                <div className="tabra3"></div>
                <p className="tabra4">{t("Details.hi5")}</p>
              </div>
              <div className="tabra5">......</div>
              <div className="tabra2">
                <div className="tabra3"></div>
                <p className="tabra4">{t("Details.hi6")}</p>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <br />
      <br />
      <br />
      <p className='TitlContwo' style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {t("Details.sub")}
      </p>
      <br />
      <div className='marginright '>
        <br />
        <p className='titlecollapsee'>{t("Details.sub1")}</p>
        <div className='articleContent'>
          <div className='det_gr'>
            <div className='gellule'>
              <p className='titlenormalcolapsText'>{t("Details.sub2")}</p>
              <p>{types.length > 0 && `${getTitleDescription(types[0].Title)}/${getBranchDescription(types[0].Branch)}`}</p>
            </div>
            <div className='gellule'>
              <p className='titlenormalcolapsText'>{t("Details.sub3")}</p>
              <p>{types.length > 0 && getBranchDescription(types[0].Location)}</p>
            </div>
            {types.length > 0 && types[0].Services && (
              <div className='gellule'>
                <p className='titlenormalcolapsText'>{t("Details.sub4")}</p>
                <p>{getNaturalBirthDescription(types[0].Services)}</p>
              </div>
            )}
            <div className='gellule'>
              <p className='titlenormalcolapsText'>{t("Details.sub5")}</p>
              <p>{types.length > 0 && (Number(types[0].PriceAtYourPlatform - (types[0].PriceAtYourPlatform * service / 100)) + (Number(types[0].pro) || 0))} {t("Services.Pur6")}</p>
            </div>
          </div>
        </div>
        <br />
        <div dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
          {types.length > 0 && types[0].IncludedServices.map((service, index) => (
           <div key={index} >
           <div className='titlecollapseeB'>
               <div className='Bluediv'>
                   <p className='titlecollapseeWak'>{service.title}</p>
               </div>
           </div>
           <ul>
               {service.description.map((desc, idx) => (
                   <li key={idx} className="totire">
                       <img src={icon2Img} alt="Icon" />
                       <span>{desc}</span>
                   </li>
               ))}
           </ul>
       </div>
       
          ))}
        </div>
        <div className='Bluediv'>
          <p className='titlecollapseeWak'>{t("Details.addedValues")}</p>
        </div>
        <br />
        <div dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
          {types.length > 0 && types[0].AddedValues.map((value, index) => (
            <div className='titlecollapseeB' key={index}>
              <ul>
                <li key={index} className="totire">
                  <img src={icon2Img} alt="Icon" />
                  <span>{value}</span>
                </li>
              </ul>
            </div>
          ))}
        </div>
        <div className='Bluediv ' >
          <p className='titlecollapseeWak'>{t("Details.specialConditions")}</p>
        </div>
        <br />
        <br />
        <div className={`${i18n.language === 'ar' ? 'rtl' : 'ltr'} titlecollapseeB`} dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
          <ul style={{ padding: 0 }}>
            {types.length > 0 && types[0].SpecialConditions.map((condition, index) => (
              <li className="circle-list-itemDetails" key={index}>{condition}</li>
            ))}
          </ul>
        </div>
      </div>
      <div className='Bluediv'>
        <p className='titlecollapseeWak'>{t("Details.generalConditions")}</p>
      </div>
      <br />
      <br />
      <div className={`${i18n.language === 'ar' ? 'rtl' : 'ltr'} titlecollapseeB`} dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
        <ul style={{ padding: 0 }}>
          {types.length > 0 && types[0].GeneralConditions.map((condition, index) => (
            <li className="circle-list-itemDetails" key={index}>{condition}</li>
          ))}
        </ul>
      </div>
      <br />
      <div className="text-center">
        <button className='blueNewTkhile' onClick={handleButtonClick}>{t("Services.Pur16")}</button>
        <br />
        <br />
      </div>
    </div>
  );
};

export default Details;
